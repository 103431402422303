<template>
  <v-card flat>
    <v-card-text>
      <v-form>
        <v-row>
          <v-col cols="12" md="12">
            <label class="text-subtitle-2">Sobre Nós</label>
            <VueEditor
              v-model="currentClient.about_us"
              class="background"
            />
          </v-col>
          <v-col cols="12" md="12">
            <label class="text-subtitle-2">Localização (Google Maps)</label>
            <v-textarea
              outlined
              dense
              v-model="currentClient.maps_embed"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" text @click="$emit('close')"> Cancelar </v-btn>
      <v-btn color="primary" @click="update"> Salvar </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  props: {
    currentClient: {
      type: Object,
      required: true,
    },
  },
  components: {
    VueEditor,
  },
  data: () => ({
    //
  }),
  methods: {
      update() {
      this.$emit("update");
      this.$emit("close");
    },
  }
};
</script>

<style>
</style>